<ng-container *ngIf="appComponentService.refreshAllOnActiveUserChange$ | async" />
<ng-container *ngIf="appComponentService.refreshAllOnLoggedInUserChange$ | async" />
<ng-container *ngIf="appComponentService.refreshAllOnActiveCompanyChange$ | async" />
<ng-container *ngIf="appComponentService.openAcceptSubscriptionPlanPoliciesModal$ | async" />
<ng-container *ngIf="cachedUserSearchResultsService.storeActiveCompanyInUserCachedResults$ | async" />
<ng-container *ngIf="cachedUserSearchResultsService.resetLabelsOwnCompany$ | async" />
<ng-container *ngIf="vendorInvoiceSocketService.updateVendorInvoiceFromSocket$ | async" />
<ng-container *ngIf="salesInvoiceSocketService.updateSalesInvoiceFromSocket$ | async" />
<ng-container *ngIf="actionableTasksService.listenSocketForActionableTasks$ | async" />
<ng-container *ngIf="notificationStateService.listenSocketForCustomerTodo$ | async" />
<ngx-loading-bar [includeSpinner]="false" height="3px" color="#00bb81" />
<dougs-flash-messages />
<ng-container
  *ngIf="
    (userStateService.loggedInUser$ | async) && (userStateService.loggedInUser$ | async)?.isSignupCompleted;
    else elseBlock
  "
>
  <ng-container *ngIf="actionOnQueryParamsService.openPlanModalOnQueryParams$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.openUserConversationModalOnQueryParams$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.openPlanModalCampaignOnQueryParams$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.queryParamsAction$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.queryParamsVendorInvoice$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.queryParamsSynchronizedShop$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.queryParamsSalesInvoice$ | async" />
  <ng-container *ngIf="actionOnQueryParamsService.queryParamsQuote$ | async" />
  <dougs-flash-messages />
  <ng-container *ngIf="userStateService.activeUser$ | async">
    <dougs-sidebar
      *ngIf="!router.url.startsWith('/signin') && (companyServicesStateService.services$ | async)"
      class="sidebar"
      [isControlPanelOpened]="openControlPanelComponentService.isControlPanelOpened$ | async"
      [isCompact]="sidebarCompactComponentService.compactSidebar$ | async"
    >
      <router-outlet />
    </dougs-sidebar>
    <dougs-control-panel *ngIf="appComponentService.shouldShowControlPanel$ | async" />
    <dougs-intercom *ngIf="(companyServicesStateService.services$ | async)?.features?.hasIntercom" />
  </ng-container>
</ng-container>
<ng-template #elseBlock>
  <router-outlet />
  <dougs-intercom />
</ng-template>
