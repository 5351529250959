import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { AutosizeModule } from 'ngx-autosize';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { ScrollOnNavigationService } from '@dougs/core/scroll';
import { FlashMessagesComponent } from '@dougs/ds';
import { NotificationStateService } from '@dougs/notifications/shared';
import { CouponsStateService } from '@dougs/revenue/coupons/shared';
import { AccountingProductsTourService } from '@dougs/revenue/products-tour/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { SalesInvoiceSocketService } from '@dougs/sales-invoice/ui';
import { SidebarComponent } from '@dougs/sidebar';
import { ActionableTasksService } from '@dougs/task/task-actions';
import {
  ControlPanelComponent,
  OpenControlPanelComponentService,
  SidebarCompactComponentService,
} from '@dougs/task/ui';
import { CachedUserSearchResultsService, UserStateService } from '@dougs/user/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IntercomComponent } from '@dougs/user/ui';
import { VendorInvoiceSocketService } from '@dougs/vendor-invoice/ui';
import { ActionOnQueryParamsService } from './services/action-on-query-params.service';
import { AppComponentService } from './services/app.component.service';

Chart.register(LineController, LinearScale, Title, Tooltip, Legend, CategoryScale, PointElement, LineElement, Filler);

@Component({
  selector: 'dougs-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FlashMessagesComponent,
    SidebarComponent,
    IntercomComponent,
    LoadingBarModule,
    RouterModule,
    AutosizeModule,
    ControlPanelComponent,
  ],
  providers: [AppComponentService],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms ease-in-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('300ms ease-in-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'app.dougs.fr';

  constructor(
    public readonly appComponentService: AppComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly router: Router,
    public readonly companyServicesStateService: CompanyServicesStateService,
    public readonly cachedUserSearchResultsService: CachedUserSearchResultsService,
    public readonly vendorInvoiceSocketService: VendorInvoiceSocketService,
    public readonly salesInvoiceSocketService: SalesInvoiceSocketService,
    public readonly openControlPanelComponentService: OpenControlPanelComponentService,
    public readonly sidebarCompactComponentService: SidebarCompactComponentService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly logger: LoggerService,
    private readonly couponsStateService: CouponsStateService,
    private readonly scrollOnNavigationService: ScrollOnNavigationService,
    public readonly accountingProductsTourService: AccountingProductsTourService,
    public readonly actionableTasksService: ActionableTasksService,
    public readonly actionOnQueryParamsService: ActionOnQueryParamsService,
    public readonly notificationStateService: NotificationStateService,
  ) {}

  @HostListener('document:copy', ['$event'])
  copyFromDougs(e: ClipboardEvent): void {
    const selection: Selection | null = document.getSelection();
    if (selection && selection.toString()) {
      e.clipboardData?.setData('text/plain', selection.toString().replace(' ', ' '));
      e.preventDefault();
    }
  }

  @HostListener('document:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent): Promise<void> {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      await this.appComponentService.openSearchUserModal(event);
    } else if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
      await this.appComponentService.openCompanySummaryModal(event);
    }
  }

  async ngOnInit(): Promise<void> {
    try {
      this.scrollOnNavigationService.handleScrollOnNavigation();
      await this.gtmService.addGtmToDom();
    } catch (e) {
      this.logger.error(e);
    }

    const queryParamsSnapshot: URLSearchParams = new URLSearchParams(window.location.search);
    if (queryParamsSnapshot.has('c')) {
      void this.couponsStateService.addCouponFromUser(queryParamsSnapshot.get('c') as string);
    }

    this.appComponentService.changeFaviconsByAccountingFirm();
  }
}
